import globalNetsuiteService, { NetsuiteService } from "../netsuite";
import { IConfig } from "../../models/Config";
import { ConfigServiceResponse } from "./types";


const DUMMY_CONFIG: IConfig = {
    locations: [
        { id: '1', text: 'Corporate' },
        { id: '12', text: 'Miami' },
        { id: '9', text: 'San Francisco' },
    ]
};

export class ConfigService {

    private _ns: NetsuiteService;

    constructor(ns: NetsuiteService) {
        this._ns = ns;
    }

    async load(): Promise<ConfigServiceResponse.Load> {

        // const res = await this._ns.jsonp<IConfig>({
        //     aaa_action: 'get-calendar-config'
        // });


        return { config: DUMMY_CONFIG };

    }
}

export const globalConfigService = new ConfigService(globalNetsuiteService);
