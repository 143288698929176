import React from 'react';
import ConfigContextProvider from '../ConfigContext';
import UserContextProvider from '../UserContext';
import InstallContextProvider from '../InstallContext';
import ResourceView from '../ResourceView';
import styles from './App.module.css';

function App() {
  return (

    <ConfigContextProvider>
      <UserContextProvider>
        <InstallContextProvider>
          <div className={styles.container}>
            <ResourceView />
          </div>
        </InstallContextProvider>
      </UserContextProvider>
    </ConfigContextProvider>

  );
}

export default App;
