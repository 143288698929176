
export type QueryStringDataType = string | boolean | number | void | Object

export interface QueryStringParams {
    [key: string]: QueryStringDataType | Array<QueryStringDataType>
};

export abstract class QueryStringSerializer<T = any> {

    protected _key: string;
    protected _value: T;

    constructor(key: string, value: T) {
        this._key = key;
        this._value = value;
    }

    abstract serialize(): string;

}

class PrimitiveSerializer extends QueryStringSerializer<string | boolean | number> {

    public serialize(): string {
        return `${encodeURIComponent(this._key)}=${encodeURIComponent(this._value)}`;
    }

}

class ArraySerializer extends QueryStringSerializer<Array<QueryStringDataType>> {

    public serialize(): string {

        return this._value.map((item, index) => {

            const itemKey = `${this._key}[${index}]`;
            const itemSerializer = QueryStringSerializerFactory.build(itemKey, item);

            return itemSerializer.serialize();

        }).join('&');

    }

}

class ObjectSerializer extends QueryStringSerializer<QueryStringParams> {

    public serialize(): string {

        return Object.entries(this._value).map(([key, item]) => {

            const itemKey = `${this._key ? `${this._key}.` : ''}${key}`;
            const itemSerializer = QueryStringSerializerFactory.build(itemKey, item);

            return itemSerializer.serialize();

        }).join('&');

    }

}

class VoidSerializer extends QueryStringSerializer<void> {

    public serialize(): string {
        return `${encodeURIComponent(this._key)}=`;
    }

}

export class QueryStringSerializerFactory {

    static build(key: string, value: QueryStringDataType | Array<QueryStringDataType>): QueryStringSerializer {

        if (value == null) {
            return new VoidSerializer(key, value);
        } else if (value.toString() === '[object Object]') {
            return new ObjectSerializer(key, value as QueryStringParams);
        } else if (Array.isArray(value)) {
            return new ArraySerializer(key, value);
        } else if (['string', 'boolean', 'number'].includes(typeof value)) {
            return new PrimitiveSerializer(key, value as (string | number | boolean));
        } else {
            throw new TypeError(`[QueryStringSerializerFactory] - build() - Unsupported value type passed: ${typeof value}`);
        }

    }

}
