import React from 'react';
import { globalConfigService } from '../../services/config';
import { GenericSummary } from '../../shared/types';


export interface ConfigContextValue {
    locations: GenericSummary[];
    loading: boolean;
}

export interface ConfigContextState {
    locations: GenericSummary[];
    loading: boolean;
}

export const ConfigContext = React.createContext<ConfigContextValue | null>(null)
export const useConfigContext = (): ConfigContextValue => {

    const ctx = React.useContext(ConfigContext);

    if (!ctx) {
        throw new Error('INVALID_CONTEXT_USAGE');
    }

    return ctx;

}

export interface ConfigContextProps {
    children: React.ReactNode;
}

const ConfigContextProvider = ({
    children
}: ConfigContextProps) => {

    const [state, setState] = React.useState<ConfigContextState>({
        locations: [],
        loading: true,
    });

    React.useEffect(() => {
        (async () => {

            const { config } = await globalConfigService.load()

            setState((oldState) => {
                return {
                    ...oldState,
                    locations: config.locations,
                    loading: false,
                }
            });

        })();
    }, []);

    return (
        <ConfigContext.Provider value={state}>
            {children}
        </ConfigContext.Provider>
    );

}

export default ConfigContextProvider;
