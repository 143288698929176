import React from 'react'

const useMultiRef = <T = unknown>(...refs: (React.Ref<T> | void)[]): React.RefObject<T> => {

    const hostRef = React.useRef<T>(null);

    React.useEffect(() => {

        refs.forEach(ref => {

            if (typeof ref === 'function') {
                ref(hostRef.current);
            } else if (ref) {
                (ref as any).current = hostRef.current;
            }

        })

    }, [refs]);

    return hostRef;

}

export default useMultiRef;
