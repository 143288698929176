import { ApiEndpoints, APP_ID, NetsuiteEnv } from '../../shared/constants';
import { QueryStringParams, QueryStringSerializerFactory } from "../utils";
import fetchJsonp from 'fetch-jsonp';
import axios, { AxiosRequestConfig } from 'axios';

export class NetsuiteService {

    private _baseUrl: string = ApiEndpoints.netsuite;
    private _authParamName = 'aaa_token' as const;
    private _baseParams: QueryStringParams = {
        aaa_appid: APP_ID
    };

    public resolveEndpoint(params?: QueryStringParams): string {
        return `${this._baseUrl}&${QueryStringSerializerFactory.build('', this.mixinBaseParams(params)).serialize()}`;
    }

    public mixinBaseParams(params: QueryStringParams = {}): QueryStringParams {
        return {
            ...this._baseParams,
            ...params,
        }
    }

    public setAuth(token: string) {
        this._baseParams[this._authParamName] = token;
    }

    public clearAuth() {
        delete this._baseParams[this._authParamName];
    }

    public async jsonp<T = unknown>(params?: QueryStringParams, options: fetchJsonp.Options = { timeout: 30000 }): Promise<T> {

        const res = await fetchJsonp(
            this.resolveEndpoint(params),
            options,
        );

        if (!res.ok) {
            throw new Error('RESPONSE_NOT_OK');
        }

        const payload: { success: boolean; } & T = await res.json();
        // console.log("payload", payload);
        if (payload.hasOwnProperty('success') && !payload.success) {
            throw new Error('REQUEST_NOT_SUCCESSFUL');
        }

        return payload;

    }

    public async post<T = unknown>(data: any, config?: AxiosRequestConfig<any>): Promise<T> {

        const res = await axios.post(this.resolveEndpoint(), data, config);

        if (res.status < 200 || res.status > 299) {
            throw new Error('RESPONSE_NOT_OK');
        }

        const payload: { success: boolean; } & T = res.data;
        console.log("payload", payload);
        if (payload.hasOwnProperty('success') && !payload.success) {
            throw new Error('REQUEST_NOT_SUCCESSFUL');
        }

        return payload;

    }

}

export function setNSAuth(authString: string) {
    if (process.env.REACT_APP_NETSUITE_ENV === NetsuiteEnv.SANDBOX) {
        console.warn('Setting netsuite auth token from production app');
        //globalNetsuiteService.setAuth('f6391a2b-fda4-4987-9ac3-66b999cc4c86');
        globalNetsuiteService.setAuth(authString);
        // console.log('authString',authString)
    } else {
        globalNetsuiteService.setAuth(authString);
    }

}

export function setCookie(cName: string, cValue: string, expDays: number | null = null) {
    if (expDays !== null) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    } else {
        document.cookie = cName + "=" + cValue + "; path=/";
    }
    
}


export function getCookie(cName: string): string | null {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res: string | null = null;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

export function deleteCookie(name: string) {
    document.cookie =
        `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`;

    // Remove it from local storage too
    window.localStorage.removeItem(name);
}

export function deleteAllCookies() {
    const cookies = document.cookie.split("; ");

    cookies.forEach((cookie: string) => {
        const name: string = cookie.split("=")[0];
        deleteCookie(name);
    });

    // Some sites backup cookies in `localStorage`
    window.localStorage.clear();
}


let globalNetsuiteService = new NetsuiteService();
const currentURL = window.location.search;
let urlParams = new URLSearchParams(currentURL);
let userToken = urlParams.get('userToken');
console.log("User token value obtained from URL", userToken);
if (userToken !== "" && userToken !== undefined && userToken !== null) {
    setCookie('user_token', userToken?.toString() );// setCookie('user_token', userToken?.toString(), 0.125)
    setNSAuth(userToken.toString());
} else {
    userToken = getCookie('user_token');
    if (userToken === "" || userToken === undefined || userToken === null) {
        console.warn("User token missing");
        // setNSAuth("dummy-token");//Setting a dummy user token to avoid run webpack issue
    }else{
        setNSAuth(userToken.toString());
    }

}

export default globalNetsuiteService;
