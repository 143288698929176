import React from 'react';
import { IInstall } from '../../models/Install';
import { IResourceGroup } from '../../models/ResourceGroup';
import globalInstallService from '../../services/install';
import { InstallResourceSet, InstallHolidayList } from '../../shared/types';


export interface InstallContextValue {
    installs: IInstall[];
    resourceGroups: IResourceGroup[];
    resources: InstallResourceSet;
    loading: boolean;
    holidayList: InstallHolidayList[];
}

export const InstallContext = React.createContext<InstallContextValue | null>(null)
export const useInstallContext = (): InstallContextValue => {

    const ctx = React.useContext(InstallContext);

    if (!ctx) {
        throw new Error('INVALID_CONTEXT_USAGE');
    }

    return ctx;

}

export interface InstallContextProps {
    children: React.ReactNode;
}
var splitEmployeeEquipments = (resources: any ):  {employees: any[], equipments: any[]}=>{
    // console.log("resources", resources);
    let employeeList = [], equipmentList = [], resourceList, tempStrArr = [];
    for(let i=0; i<resources.length; i++){
        tempStrArr = (resources[i].id)?.split(':');
        if(tempStrArr && tempStrArr.length > 0){
            if(tempStrArr[0] === 'EMP'){
                employeeList.push(resources[i]);
            }
            if(tempStrArr[0] === 'EQP'){
                equipmentList.push(resources[i]);
            }
        }
    }
    resourceList = {employees: employeeList, equipments: equipmentList};
    console.log("resourceList", resourceList);
    return resourceList;

}

const InstallContextProvider = ({
    children
}: InstallContextProps) => {

    const [state, setState] = React.useState<InstallContextValue>({
        installs: [],
        resourceGroups: [],
        resources: { employees: [], equipment: [] },
        loading: true,
        holidayList: []
    });

    React.useEffect(() => {
        (async () => {
            
            const [
                { installs },
                { resourceGroups },
                resources,
                holidayList,
            ] = await Promise.all([
                globalInstallService.getAll(),
                globalInstallService.getAllResourceGroups(),
                globalInstallService.getAllResource(),
                globalInstallService.getAllHolidays(),
            ]);
            
            console.log({installs,resourceGroups,resources,holidayList});
            
            
            let splitList = splitEmployeeEquipments(JSON.parse(JSON.stringify(resources)));
            setState({
                installs,
                resourceGroups,
                resources:  { employees: splitList.employees, equipment: splitList.equipments},
                loading: false,
                holidayList
            });

        })();
    }, []);


    return (
        <InstallContext.Provider value={state}>
            {children}
        </InstallContext.Provider>
    );

}

export default InstallContextProvider;
