import { DataManager, WebMethodAdaptor } from '@syncfusion/ej2-data';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { BeforeDataBoundArgs, ColumnDirective, ColumnsDirective, DialogEditEventArgs, Edit, Filter, GridComponent, RowDD, RowDragEventArgs, SaveEventArgs, Toolbar } from '@syncfusion/ej2-react-grids';
import { Inject } from '@syncfusion/ej2-react-navigations';
//import { stringify } from 'querystring';
import React from 'react';
import useMultiRef from '../../hooks/useMultiRef';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import { IResourceGroup } from '../../models/ResourceGroup';
import globalNetsuiteService from '../../services/netsuite';
import { GenericSummary, InstallResourceSet } from '../../shared/types';
import { getCookie } from '../../services/netsuite';


const dataSource = new DataManager({
    url: globalNetsuiteService.resolveEndpoint({ aaa_action: 'get-calendar-resource-groups' }),
    batchUrl: globalNetsuiteService.resolveEndpoint({ aaa_action: 'crud-calendar-resource-groups' }),
    insertUrl: globalNetsuiteService.resolveEndpoint({ aaa_action: 'crud-calendar-resource-groups' }),
    updateUrl: globalNetsuiteService.resolveEndpoint({ aaa_action: 'crud-calendar-resource-groups' }),
    removeUrl: globalNetsuiteService.resolveEndpoint({ aaa_action: 'crud-calendar-resource-groups' }),
    adaptor: new WebMethodAdaptor(),
    crossDomain: true,
});

export type ResourceGroupTreeItem = IResourceGroup & { locationText: string; };

export interface ResourceGroupsListProps {
    className?: string;
    onRowDragStop?: (evt?: RowDragEventArgs) => void;
    allResources: InstallResourceSet;
    allLocations: GenericSummary[];
}

const EmployeeColumnTemplate = ({ employees = [] }: ResourceGroupTreeItem) => {
    return <span>
        {employees.length ? employees.map(({ text }) => text).join(', ') : '(none)'}
    </span>
};

const EquipmentColumnTemplate = ({ equipment = [] }: ResourceGroupTreeItem) => {
    return <span>
        {equipment.length ? equipment.map(({ text }) => text).join(', ') : '(none)'}
    </span>
};

type EditorTemplateRenderProps = ResourceGroupTreeItem & { isAdd: boolean; };
type EditorTemplateProps = EditorTemplateRenderProps & { allResources: InstallResourceSet; allLocations: GenericSummary[]; };

const formatEmployeeName = (val: string): string => {
    let splitVal = val.toString().trim().split(' ')
    splitVal.shift();
    return splitVal.join(' ');
}


const EditorTemplate = ({
    employees = [],
    equipment = [],
    allLocations = [],
    ...props
}: EditorTemplateProps) => {

    const allResources = props.allResources ?? [];

    const [name, setName] = React.useState(props.name);
    const onNameChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => setName(evt.target.value), [setName]);
    let selectedLocation = useSelectedLocation();
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "")   selectedLocation = { 'id': locationIdCookie, 'text': locationNameCookie };

    const employeesInputRef = React.useRef<HTMLInputElement>(null);
    const equipmentInputRef = React.useRef<HTMLInputElement>(null);

    console.log("allLocations", allLocations);
    console.log("employees", employees);
    console.log("equipment", equipment);
    console.log("selectedLocation", selectedLocation);
    console.log("props", props);
    console.log("allResources", allResources);
    console.log("allResources.employees", allResources.employees);


    const allResourcesEMP: { [key: string]: string } = allResources.employees.reduce((acc, el) => {
        //@ts-ignore
        acc[el?.text?.toString()] = el.id?.toString()?.trim();
          //@ts-ignore
        acc[el?.id?.toString()] = el.id?.toString()?.trim();
        return acc;
    }, {});
    const allResourcesEQP = allResources.equipment.reduce((acc, el) => {
        //@ts-ignore
        acc[el?.text?.toString()] = el.id?.toString()?.trim();
          //@ts-ignore
        acc[el?.id?.toString()] = el.id?.toString()?.trim();
        return acc;
    }, {});
    console.log({ allResourcesEMP, allResourcesEQP });
    const allResourcesFiltered = (allResources.employees ?? []).filter((el) => !selectedLocation || el.locationId === selectedLocation.id);

    return <table className="e-table">
        <tbody>
            <tr>
                <td className="e-rowcell" style={{ textAlign: "left", width: "250px" }}>
                    <div className="e-float-input e-control-wrapper ">
                        <input className="e-field e-defaultcell" type="text" name="name" value={name} onChange={onNameChange} />
                        <span className="e-float-line"></span>
                        <label className="e-float-text e-label-top">Name</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="e-rowcell" style={{ textAlign: "left", width: "250px" }}>
                    <div className="e-float-input e-control-wrapper">
                        <MultiSelectComponent
                            id="employeeIds-editor"
                            data-name="employeeIds-editor"
                            dataSource={allResourcesFiltered as any}
                            // dataSource={allResources.employees as any}
                            mode="Delimiter"
                            fields={{ text: 'text', value: 'id' }}
                            value={employees.map(({ text }) => formatEmployeeName(text))}
                            // value={employees.map(({ text }) => text)}
                            placeholder="Employees"
                            className="e-field"
                            floatLabelType="Always"

                            // Special handling because for some reason
                            // on save, only the last selected value, as a string, is included,
                            // not all selected values, as an array of strings.
                            // We store a comma sepearted list of IDs in another input
                            // and process on a before save event.
                            change={evt => {
                                if (employeesInputRef.current) {
                                    // var empOldArray = employeesInputRef.current.value.split(",");
                                    // console.log("Logging the existing employee data", empOldArray);
                                    // console.log("Logging the added employee data", evt?.value);
                                    // console.log("typeof employeesInputRef.current.value", typeof employeesInputRef.current.value);
                                    employeesInputRef.current.value = ((evt?.value as string[]) ?? []).join(',');

                                    // console.log(" employeesInputRef evt?.value", evt?.value);
                                    // console.log(" employeesInputRef.current.value", (evt?.value as string[])?.map(el=>{

                                    //     console.log('allResourcesEMP[el]',allResourcesEMP[el])
                                    //     console.log('el',el)

                                    //     return allResourcesEMP[el]
                                    // }));
                                }
                            }}

                        />
                        <span className="e-float-line"></span>
                    </div>
                </td>
            </tr>

            <tr>
                {/* <tr className="hidden-input"> */}
                {/* Was hidden because no equipments present earlier. */}
                <td className="e-rowcell" style={{ textAlign: "left", width: "250px" }}>
                    <div className="e-float-input e-control-wrapper">
                        <MultiSelectComponent
                            id="equipmentIds-editor"
                            data-name="equipmentIds-editor"
                            dataSource={allResources.equipment as any}
                            mode="Delimiter"
                            fields={{ text: 'text', value: 'id' }}
                            value={equipment.map(({ text }) => text)}
                            placeholder="Equipment"
                            className="e-field"
                            floatLabelType="Always"

                            // See note for employee editor above for explanation.
                            change={evt => {
                                if (equipmentInputRef.current) {
                                     //@ts-ignore
                                    let selectedEqp = ((evt?.value as string[])?.map(el=> allResourcesEQP[el?.toString()?.trim()]) ?? []);
                                    // equipmentInputRef.current.value =  selectedEqp.filter((c, index) => {
                                    //     return selectedEqp.indexOf(c) !== index;
                                    // }).join(',')
                                    equipmentInputRef.current.value =selectedEqp.join(',');
                                    
                                  
                                }
                            }}
                        />
                        <span className="e-float-line"></span>
                    </div>
                </td>
            </tr>

            <tr>
                <td className="e-rowcell" style={{ textAlign: "left", width: "250px" }}>
                    <div className="e-float-input e-control-wrapper ">
                        <DropDownListComponent
                            id="locationId"
                            data-name="locationId"
                            floatLabelType="Always"
                            placeholder="Location"
                            dataSource={allLocations as any}
                            fields={{ text: 'text', value: 'id' }}
                            value={props.location ? props.location.id : selectedLocation ? selectedLocation.id : undefined}
                        />
                    </div>
                </td>
            </tr>


            {/* Another weird bug. Using name="isPrimary", which is what we want,
            results in the input being treated as type='text', and sets 'isPrimary'
            in the payload to '' regardless of the 'checked' state. We remap this value,
            which is correctly included in the form payload under the key 'isPrimary-editor'
            to 'isPrimary' in the beforeSave event handler */}
            <tr>
                <td className="e-rowcell" style={{ textAlign: "left", width: "250px" }}>
                    <div className="e-float-input e-control-wrapper ">
                        <CheckBoxComponent
                            id="isPrimary"
                            name="isPrimary-editor"
                            checked={!!props.isPrimary}
                            label='Is Primary'
                            className="e-field"
                        />
                    </div>
                </td>
            </tr>

            {/*
              * A set of hidden inputs to hold comma separated list of selected resources.
              * Used because the form doesn't work correctly with MultiSelectComponent/CheckBoxComponent natively
              */}
            <tr className="hidden-input">
                <td className="e-rowcell">
                    <input ref={employeesInputRef} className="e-field" type="hidden" name="employeeIds" value={employees.map(({ id }) => ('EMP:' + id)).join(',')} />
                </td>
            </tr>
            <tr className="hidden-input">
                <td className="e-rowcell">
                    <input ref={equipmentInputRef} className="e-field" type="hidden" name="equipmentIds" value={equipment.map(({ id }) => ('EQP:' + id)).join(',')} />
                </td>
            </tr>

            {/* <tr className="">
                <td className="e-rowcell">
                    <input ref={employeesInputRef} className="e-field" type="" name="employeeIds" value={employees.map(({ id }) => ('EMP:' + id)).join(',')} />
                </td>
            </tr>
            <tr className="">
                <td className="e-rowcell">
                    <input ref={equipmentInputRef} className="e-field" type="" name="equipmentIds" value={equipment.map(({ id }) => ('EQP:' + id)).join(',')} />
                </td>
            </tr> */}

        </tbody>
    </table>

}

const ResourceGroupsGrid = React.forwardRef<GridComponent, ResourceGroupsListProps>((
    {
        className = '',
        onRowDragStop,
        allResources,
        allLocations
    },
    ref
) => {

    const gridRef = React.useRef<GridComponent>(null);
    const multiRef = useMultiRef<GridComponent>(ref, gridRef);
    let selectedLocation = useSelectedLocation();
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "")   selectedLocation = { 'id': locationIdCookie, 'text': locationNameCookie };

    React.useEffect(() => {

        if (!gridRef.current) return;

        if (!selectedLocation) {
            gridRef.current.clearFiltering(['location']);
        } else {
            gridRef.current.filterByColumn('location.text', 'equal', selectedLocation.text);
        }

    }, [selectedLocation]);

    const handleActionBegin = (evt: SaveEventArgs) => {

        // We manually set some values here
        // because the MultiSelectComponent doesn't work with the edit modal
        if (evt.requestType === 'save') {


            console.log("All save event data", evt.data);
            // Parse the ids as saved by the EditorTemplate
            const employeeIds: string[] = (evt.data as any)?.employeeIds ? ((evt.data as any)?.employeeIds as string).split(',') : [];
            console.log("employeeIds", employeeIds);
            const equipmentIds: string[] = (evt.data as any)?.equipmentIds ? ((evt.data as any)?.equipmentIds as string).split(',') : [];
            console.log("equipmentIds", equipmentIds);
            const selectedEmployees = allResources.employees.filter(({ id }) => employeeIds.includes(id));
            const selectedEquipment = allResources.equipment.filter(({ id }) => equipmentIds.includes(id));
            const isPrimary = !!(evt.data as any)['isPrimary-editor'];

            // Data that will be sent to server
            console.log("selectedEmployees.length, selectedEquipment.length", { 'emp': selectedEquipment.length, 'equ': selectedEquipment.length });
            console.log("evt.data", evt.data);
            console.log("selectedEmployees", (evt.data as ResourceGroupTreeItem).employees);
            console.log("selectedEquipment", (evt.data as ResourceGroupTreeItem).equipment);
            //console.log("Group location", (evt.data as any).locationId);
            let tempArr: GenericSummary[] = [];
            if (evt.data != null) {
                tempArr = ((evt.data as ResourceGroupTreeItem).employees);
                console.log("tempArr", tempArr);
            }
            let newEmpArray: string[] = [];
            if (tempArr && Array.isArray(tempArr) && tempArr.length > 0) {
                newEmpArray = tempArr.map(elem => { return "EMP:" + (elem.id).toString() });
            }
            let existingEmpData = [];
            existingEmpData = (allResources.employees).filter(e => newEmpArray.includes(e.id));
            (evt.data as ResourceGroupTreeItem).employees = [...existingEmpData, ...selectedEmployees];
            (evt.data as ResourceGroupTreeItem).equipment = [...selectedEquipment];
            (evt.data as any).location = (allLocations.filter(el => el.id === (evt.data as any).locationId))[0];

            console.log("Group location", (evt.data as any).locationId);


            (evt.data as ResourceGroupTreeItem).isPrimary = isPrimary;

            // Cleanup unnecessary/temporary values
            delete (evt.data as any)['isPrimary-editor'];
            delete (evt.data as any)['employeeIds-editor'];
            delete (evt.data as any)['employeeIds'];
            delete (evt.data as any)['equipmentIds-editor'];
            delete (evt.data as any)['equipmentIds'];


        }
        console.log("Save data: evt.data: ", evt.data);

    }

    // Manually refresh the data grid when edit's saved
    // because the rows aren't updated correctly otherwise
    // Also adds custom validation rules to the edit/create modals
    const handleActionComplete = (evt: SaveEventArgs | DialogEditEventArgs) => {
        if (evt.requestType === 'save' && (evt as SaveEventArgs).action === 'edit') {
            gridRef.current?.refresh();
        } else if ((evt.requestType === 'beginEdit' || evt.requestType === 'add') && (evt as DialogEditEventArgs).form) {
            /** Add Validation Rules */
            const form: FormValidator = (evt as DialogEditEventArgs).form!.ej2_instances[0];
            form.addRules('name', { required: true });
            form.addRules('employeeIds-editor', { required: true });
            form.addRules('locationId', { required: true });
        }
    }

    // A simple custom view filter. Used so we don't have to implement
    // filtering on the server side.
    const handleBeforeDataBound = React.useCallback((evt?: BeforeDataBoundArgs) => {
        if (evt && evt.result && selectedLocation) {
            evt.result = (evt.result as ResourceGroupTreeItem[]).filter(({ location: { id } }) => id === selectedLocation?.id)
        }
    }, [selectedLocation]);

    return (
        <GridComponent
            className={className}
            dataSource={dataSource}
            beforeDataBound={handleBeforeDataBound}
            allowRowDragAndDrop={true}
            rowDropSettings={{
                targetID: 'something-that-will-never-actually-exist'
            }}
            ref={multiRef}
            height='410'
            toolbar={['Add', 'Edit', 'Delete']}
            rowDrop={onRowDragStop}
            allowFiltering={true}
            filterSettings={{
                type: "CheckBox",
            }}
            editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                mode: 'Dialog',
                template: (props: EditorTemplateRenderProps) => <EditorTemplate {...props} allResources={allResources} allLocations={allLocations} />,
                showDeleteConfirmDialog: true,

            }}
            actionBegin={handleActionBegin as any}
            actionComplete={handleActionComplete as any}
        >
            <ColumnsDirective>
                <ColumnDirective field='id' headerText='Id' width='140' visible={false} isPrimaryKey={true}></ColumnDirective>
                <ColumnDirective field='name' headerText='Name' width='140' allowFiltering={false}></ColumnDirective>
                <ColumnDirective field='employees' headerText='Employees' width='140' template={EmployeeColumnTemplate} allowFiltering={false}></ColumnDirective>
                <ColumnDirective field='location.text' headerText='Location' width='140' ></ColumnDirective>
                <ColumnDirective field='equipment' headerText='Equipment' visible={false} width='140' template={EquipmentColumnTemplate}></ColumnDirective>
                <ColumnDirective field='isPrimary' headerText='Primary' width='100' displayAsCheckBox={true} allowFiltering={false}></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, RowDD, Filter]} />
        </GridComponent>
    );

})

export default ResourceGroupsGrid;
