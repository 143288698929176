import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { DropDownListComponent, DropDownListModel } from '@syncfusion/ej2-react-dropdowns';
import React from 'react';
import { GenericSummary } from '../../shared/types';
import locationSubject from '../../subscriptions/location';
import { getCookie, setCookie } from '../../services/netsuite';


// Type derrived from typeof DropdownListComponent.props
export type ILocationSelectorProps = DropDownListModel & DefaultHtmlAttributes & {
    locations: GenericSummary[];
    selectedId?: string;
    allowAny?: boolean;
}

const ANY_ID = '__ANY__' as const;

const LocationSelector = ({
    selectedId,
    locations = [],
    allowAny = true,
    change,
    ...props
}: ILocationSelectorProps) => {

    const dataSource = React.useMemo(() => {
        if (!allowAny) return locations;
        return [{ text: "Any", id: ANY_ID }, ...locations]
    }, [locations, allowAny]);
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "") selectedId = locationIdCookie;// Check for location filter values in the cookies
    else selectedId = undefined;

    return (
        <DropDownListComponent
            dataSource={dataSource as any}
            value={selectedId ?? (allowAny ? ANY_ID : undefined)}
            fields={{ text: 'text', value: 'id' }}
            change={evt => {
                if (evt) {

                    let location: GenericSummary = evt.itemData as any;
                    
                    if (location.id === ANY_ID) {
                        // If location value 'Any' is selected, clear location filter cookies
                        setCookie('location_id', "");
                        setCookie('location_name', "");
                        locationSubject.next();
                    } else {
                        // If any other location value is selected, update location filter cookies
                        setCookie('location_id', location.id);
                        setCookie('location_name', location.text);
                        locationSubject.next(location);
                    }

                }
                if (typeof change === 'function') change(evt);
            }}
            {...props}
        />
    );
};

export default LocationSelector;
