import React, { useEffect } from 'react';
import { useRef } from 'react';
import globalInstallService from '../../services/install';
import { SCHEDULE_PROJECT_URL } from '../../shared/constants';
import { getCookie, setCookie, deleteAllCookies } from '../../services/netsuite';


export interface UserContextValue {
    user: {
        roleId: number,
        accessLevel: 'VIEW' | 'FULL'
    }
}

export const userContext = React.createContext<UserContextValue | null>(null);
export const useUserContext = (): UserContextValue | null => {

    const ctx = React.useContext(userContext);

    return ctx;

}

export interface UserContextState {
    user: {
        roleId: number,
        accessLevel: 'VIEW' | 'FULL'
    };
}

export interface UserContextProps {
    children: React.ReactNode;
}

export function setAccessLevel() {
    let cookieArray = decodeURIComponent(document.cookie).split(";");
    let accessLevel = "";
    cookieArray.map(el => {
        if ((el.split("="))[0] === 'access_level') {
            accessLevel = el.split("=")[1];
        }
        return true;
    });
    return accessLevel;
}



const UserContextProvider = ({
    children
}: UserContextProps) => {


    const [state, setState] = React.useState<UserContextValue | null>(null);
    const [shouldDisplay, setDisplay] = React.useState<string>('');
    // const intervalTimerId = useRef<null | NodeJS.Timer>(null);

    useEffect(() => {
        (async () => {


            const currentURL = window.location.search;
            let urlParams = new URLSearchParams(currentURL);
            let encryptStr = urlParams.get('auth');
            let timeStamp = urlParams.get('timeStamp');
            let empId = urlParams.get('empId');
            let userToken = urlParams.get('userToken');
            let productionAppLink : string | undefined = process.env.REACT_APP_PROD_APP_LINK;


            if ((currentURL !== "" && currentURL !== null ) && (timeStamp !== "" && timeStamp !== null ) && (empId !== "" && empId !== null ) && (userToken !== "" && userToken !== null )) {

                let timeStampValue = parseInt(decodeURIComponent(timeStamp ? timeStamp : ''));
                let empIdValue = parseInt(decodeURIComponent(empId ? empId : ""));
                // let currentTime: number = parseInt((Date.now() / 1000).toString());
                // if (((timeStampValue < currentTime) && (currentTime < (timeStampValue + 10800)))) {
                //     console.log("Session time still active");
                    if (encryptStr !== "") {
                        let encryptStrValue = decodeURIComponent(encryptStr ? encryptStr : "############").toString();
                        console.log("Encrypted string received after decode", encryptStrValue);
                        let userTokenValue = decodeURIComponent(userToken ? userToken : "").toString();
                        let accessGranted = await globalInstallService.checkAccess(timeStampValue.toString(), encryptStrValue, empIdValue.toString(), userTokenValue?.toString());
                        if (accessGranted === 'FULL') {
                            // document.cookie = 'access_level=FULL;expires=0.125;path=/';
                            setCookie('access_level', accessGranted);// setCookie('access_level', accessGranted, 0.125);
                            setCookie('empIdValue', empIdValue?.toString());// setCookie('empIdValue', empIdValue?.toString(), 0.125);
                            //@ts-ignore
                            setState((oldState) => {
                                return {
                                    ...oldState,
                                    user: {
                                        roleId: 4,
                                        accessLevel: accessGranted
                                    }
                                }
                            });
                            window.open(SCHEDULE_PROJECT_URL, '_self');
                        } else if (accessGranted === 'VIEW') {
                            setCookie('access_level', accessGranted);// setCookie('access_level', accessGranted, 0.125);
                            setCookie('empIdValue', empIdValue?.toString());// setCookie('empIdValue', empIdValue?.toString(), 0.125);
                            //@ts-ignore
                            setState((oldState) => {
                                return {
                                    ...oldState,
                                    user: {
                                        roleId: 4,
                                        accessLevel: accessGranted
                                    }
                                }
                            });
                            window.open(SCHEDULE_PROJECT_URL, '_self');
                        } else {
                            console.warn("Authorization failed");
                            deleteAllCookies();
                            setDisplay('<html>Authorization failed, <a href='+productionAppLink+'>Click here</a> to redirect to production app</html>');
                        }
                    } else {
                        console.warn("No authentication string");
                        deleteAllCookies();
                        setDisplay('<html>Missing Authentication value, <a href='+productionAppLink+'>Click here</a> to redirect to production app</html>');
                    }
                // } else {
                //     console.warn("Session expired");
                //     deleteAllCookies();
                //     setDisplay('<html>Session timed out, <a href='+productionAppLink+'>Click here</a> to open scheduling app from production app</html>');
                // }
            } else {
                let currentCookie = getCookie('access_level');
                let empIdCookie = getCookie('empIdValue');
                if (currentCookie && ['VIEW', 'FULL'].includes(currentCookie) && empIdCookie){
                    //@ts-ignore
                    setState((oldState) => {
                        return {
                            ...oldState,
                            user: {
                                roleId: 4,
                                accessLevel: currentCookie?.toString()
                            }
                        }
                    });
                } else {
                    console.warn("No authentication Cookie");
                    console.log(process.env)
                    deleteAllCookies();
                    setDisplay('<html>Authentication values not found, <a href='+productionAppLink+'>Click here</a> to redirect to production app</html>');
                }
                // console.log('intervalTimerId.current', intervalTimerId.current);

                // if (intervalTimerId.current !== undefined) {
                //     intervalTimerId.current = setInterval(() => {
                //         let currentCookie = getCookie('access_level');
                //         console.log('Scheduled cookie checking running', currentCookie);
                //         if (currentCookie === "" || currentCookie === null || currentCookie === undefined) {
                //             //@ts-ignore
                //             clearInterval(intervalTimerId.current);
                //             console.warn("No authentication Cookie");
                //             deleteAllCookies();
                //             setDisplay('<html>Session timed out, <a href='+productionAppLink+'>Click here</a> to redirect to production app</html>');
                //         }
                //     }, 900000);
                // }

            }

        })();

    }, []);

    if (state?.user?.accessLevel && state?.user?.roleId) {
        return (
            <userContext.Provider value={state} >
                {(shouldDisplay && shouldDisplay.length) ? {shouldDisplay} : children}
            </userContext.Provider>
        );
    }
    return <userContext.Provider value={state}>
        <div dangerouslySetInnerHTML={ { __html: shouldDisplay } }></div>
    </userContext.Provider>

}

export default UserContextProvider;
