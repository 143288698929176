import React from 'react';
import { GenericSummary } from '../shared/types';
import locationSubject from '../subscriptions/location';

const useSelectedLocation = (): GenericSummary | void => {

    const [location, setLocation] = React.useState<GenericSummary | void>();

    React.useEffect(() => locationSubject.subscribe(setLocation), []);
    console.log('location', location);

    return location;

}

export default useSelectedLocation;
