import { InstallResource, IResourceGroup } from "../models/ResourceGroup";
import { ResourceType, RESOURCE_TYPE_KEY_DELIM } from "./constants";
import { GenericSummary, InstallResourceSet } from "./types";
interface InstallResourceArr extends GenericSummary {
    departmentId: string;
    departmentName: string;
    isInstallLoc: boolean;
    locationId: string;
};

export const ResourceUtils = {
    
    buildResourceIds({
        employees = [],
        equipment = []
    }: { employees?: InstallResource[]; equipment?: GenericSummary[] }) {//}: { employees?: InstallResource[]; equipment?: InstallResource[] }) {
        return [
            ...employees.map(({ id }) => `${ResourceType.EMPLOYEE}${RESOURCE_TYPE_KEY_DELIM}${id}`),
            ...equipment.map(({ id }) => `${ResourceType.EQUIPMENT}${RESOURCE_TYPE_KEY_DELIM}${id}`)
        ]
    },

    fromResourceIds(keys: string[] = []): { employees: string[]; equipment: string[] } {

        const bag: { employees: string[]; equipment: string[] } = {
            employees: [],
            equipment: []
        };

        return keys.reduce((categories, key) => {

            const [id, type] = this.getInfoFromKey(key);

            if (type === ResourceType.EMPLOYEE) {
                categories.employees.push(id);
            } else if (type === ResourceType.EQUIPMENT) {
                categories.equipment.push(id);
            }
            // console.log("bag", bag);
            return bag;

        }, bag);

    },

    getInfoFromKey(key: string): [string, ResourceType] {

        const [type, id] = key.split(RESOURCE_TYPE_KEY_DELIM);

        return [id, type as ResourceType];

    },

    mergeSetIntoList({ employees = [], equipment = [] }: InstallResourceSet): InstallResource[] {
        console.log("employees", employees);
        console.log("equipment", equipment);
        console.log("Merged set", [
            ...employees.map(({ id, ...rest }) => ({ id: this.buildResourceKey(id, ResourceType.EMPLOYEE), ...rest })),
            ...equipment.map(({ id, ...rest }) => ({ id: this.buildResourceKey(id, ResourceType.EQUIPMENT), ...rest })),
        ]);
        return [
            ...employees.map(({ id, ...rest }) => ({ id: this.buildResourceKey(id, ResourceType.EMPLOYEE), ...rest })),
            ...equipment.map(({ id, ...rest }) => ({ id: this.buildResourceKey(id, ResourceType.EQUIPMENT), ...rest })),
        ];
    },

    /**
     * Sorts the set of resources by the first primary group they appear in.
     * Assumes the resources have been merged using ResourceUtils.mergeSetIntoList (the resource type is encoded into the id).
     *
     * @param mergedResources
     * @param allGroups
     */
    groupMergedByPrimaryGroup(mergedResources: InstallResource[] = [], allGroups: IResourceGroup[] = []): InstallResource[] {

        const employeeGroupIdMap = this.getAllResourceFirstPrimaryGroupId(allGroups, ResourceType.EMPLOYEE);
        const equipmentGroupIdMap = this.getAllResourceFirstPrimaryGroupId(allGroups, ResourceType.EQUIPMENT);

        console.log("employeeGroupIdMap", employeeGroupIdMap);
        console.log("equipmentGroupIdMap", equipmentGroupIdMap);

        const groupToResourcesMap: { [groupId: string]: InstallResource[] } = {
            none: []
        };

        mergedResources.forEach(resource => {

            const [id, type] = this.getInfoFromKey(resource.id);
            const groupIdMap = (type === ResourceType.EMPLOYEE) ? employeeGroupIdMap : equipmentGroupIdMap;
            const groupKey = groupIdMap[id] ?? 'none';

            if (!groupToResourcesMap[groupKey]) {
                groupToResourcesMap[groupKey] = []
            }

            groupToResourcesMap[groupKey].push(resource);

        });
        console.log("groupToResourcesMap", groupToResourcesMap);

        let temp: any[] = Object.keys(groupToResourcesMap).reduce((bag, key) => [...bag, ...groupToResourcesMap[key]], [] as InstallResource[]);

        return this.installLocationSort( temp );

    },

    /**
     * 
     * @param resourceList Function to keep 
     * @returns 
     */
    installLocationSort(resourceList: InstallResourceArr[] = []): InstallResourceArr[] {
        const installLocList: InstallResourceArr[] = [];
        const otherEmpList: InstallResourceArr[] = [];
        if (resourceList.length > 0) {
            resourceList.map(emp => {
                if (emp?.isInstallLoc === true) {
                    installLocList.push(emp);
                } else {
                    otherEmpList.push(emp);
                }
            });
        }
        
        return installLocList.concat(otherEmpList);
     },

    getAllResourceFirstPrimaryGroupId(groups: IResourceGroup[] = [], resourceType: ResourceType): { [memberId: string]: string; } {

        return groups.reduce((resourceToGroupIdMap, group) => {

            if (!group.isPrimary) {
                return resourceToGroupIdMap;
            }

            const resources = (resourceType === ResourceType.EMPLOYEE) ? group.employees : group.equipment;

            resources.forEach(({ id }) => {
                if (!resourceToGroupIdMap[id]) {
                    resourceToGroupIdMap[id] = group.id;
                }
            });

            return resourceToGroupIdMap;

        }, {} as { [memberId: string]: string; });

    },

    buildResourceKey(id: string, type: ResourceType): string {
       // return (type === ResourceType.EMPLOYEE)?`${id}`:`${type}${RESOURCE_TYPE_KEY_DELIM}${id}`;  
       
        // return `${type}${RESOURCE_TYPE_KEY_DELIM}${id}`;
        return `${id}`;
    },

}
