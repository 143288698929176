import { IInstall } from '../../models/Install';
import { IResourceGroup } from '../../models/ResourceGroup';
import { InstallResourceSet, InstallHolidayList } from '../../shared/types';
import globalNetsuiteService, { NetsuiteService } from '../netsuite';
import { InstallServiceResponse } from "./types";


interface CheckAccessResponse {
    success: boolean;
    message: string;
    error: any[];
    data: {
        access: boolean;
        hasFullAccess: boolean;
        userTokenValue: string;
    };
}

export class InstallService {


    private _ns: NetsuiteService;
    private _actions = {
        getInstallations:'get-calendar-installs',
        getAllResourceGroups: 'get-calendar-resource-groups',
        getAllResources: 'get-calendar-resources',
        getAllHolidays: 'get-calendar-holidays',
        decryptParams: 'decrypt-params'
    }

    constructor(ns: NetsuiteService) {
        this._ns = ns;
    }

    async getAll(): Promise<InstallServiceResponse.GetAll> {

        const res = await this._ns.jsonp<{ success: boolean; installations: IInstall[] }>({
            aaa_action: this._actions.getInstallations,
        });
        return {
            installs: res.installations
        };

    }

    async getAllResourceGroups(): Promise<InstallServiceResponse.GetAllResourceGroups> {

        const res = await this._ns.jsonp<{ items: IResourceGroup[]; result: IResourceGroup[]; count: number; }>({
            aaa_action: this._actions.getAllResourceGroups
        });
        return {
            resourceGroups: res.items
        };

    }

    async getAllResource(): Promise<InstallServiceResponse.GetAllResources> {

        const res = await this._ns.jsonp<InstallResourceSet>({
            aaa_action: this._actions.getAllResources,
        });
        return res;

    }


    async getAllHolidays(): Promise<InstallServiceResponse.GetAllHolidays> {

        const res = await this._ns.jsonp<InstallHolidayList[]>({
            aaa_action: this._actions.getAllHolidays,
        });
        return res;

    }


    async checkAccess(key: string, data: string, emp_id: string, user_token: string): Promise<'FULL' | 'VIEW' | 'NONE'> {
        try {
            const res: CheckAccessResponse = await this._ns.post({
                aaa_action: this._actions.decryptParams,
                aaa_module: 'login-auth',
                time_stamp: key,
                encrypt_str: data,
                emp_id: emp_id,
                user_token: user_token
            });
            // const res: CheckAccessResponse = await this._ns.jsonp({
            //     aaa_action: this._actions.decryptParams,
            //     params: {
            //         aaa_module: 'login-auth',
            //         time_stamp: key,
            //         encrypt_str: data
            //     }
            // });
            console.warn('CheckAccessResponse', res);
            if (res.success === true) {
                if (res?.data?.access)
                    if (res?.data?.hasFullAccess)
                        return 'FULL';
                    else
                        return 'VIEW';
                else
                    return 'NONE';
            }
        } catch (err) { }
        return 'NONE';

    }
}

const globalInstallService = new InstallService(globalNetsuiteService);

export default globalInstallService;
