import { GenericSummary } from "../shared/types";

export enum InstallStatus {
    PLANNING = '1',
    PENDING_SCHEDULE = '2',
    SCHEDULED = '3',
    IN_PROGRESS = '4',
    COMPLETE = '5',
    SAVE_THE_DATE = '6',
    PENDING_SAVE_THE_DATE = '7',
    CANCELED = '8',
}

export const InstallStatusLabel: {
    [status in InstallStatus]: string;
} = {
    [InstallStatus.PLANNING]: 'Planning',
    [InstallStatus.PENDING_SCHEDULE]: 'Schedule (Pending)',
    [InstallStatus.SCHEDULED]: 'Scheduled',
    [InstallStatus.IN_PROGRESS]: 'In Progress',
    [InstallStatus.COMPLETE]: 'Complete',
    [InstallStatus.SAVE_THE_DATE]: 'Save the Date',
    [InstallStatus.PENDING_SAVE_THE_DATE]: 'Save the Date (Pending)',
    [InstallStatus.CANCELED]: 'Canceled',
}

export enum InstallJobType {
    INSTALLATION = '1',
    REMOVAL = '2',
    SITE_SURVEY = '3',
    RELOCATION = '4',
}

export const InstallJobTypeLabel: {
    [status in InstallJobType]: string;
} = {
    [InstallJobType.INSTALLATION]: 'Installation',
    [InstallJobType.REMOVAL]: 'Removal',
    [InstallJobType.SITE_SURVEY]: 'Site Survey',
    [InstallJobType.RELOCATION]: 'Relocation',
}

export interface IInstall {
    id: string;
    aaaLocation: GenericSummary;
    created: string;
    companyname: string;
    customerId: string;
    details: string;
    location: IInstallLocation;
    salesteam: string;
    jobName: string;
    jobType: GenericSummary;//jobType: string;
    jobTypeId: InstallJobType;
    startDate: string;
    startTime: string;
    dueDate: string;
    dueTime: string;
    jobStatus: string;
    jobStatusId: InstallStatus;
    tranId: string;
    completed: number;
    completedBy: GenericSummary;
    completeNotificationSent: number;
    lastModified: number;
    photos: any[];
}

export interface IInstallLocation {
    addr1: string;
    addr2: string;
    addrText: string;
    city: string;
    contact: string;
    mobile: string;
    name: string;
    notes: string;
    phone: string;
    state: string;
    zip: string;
}

export const InstallUtils = {
    resolveAppointmentInterval({
        startDate: start,
        startTime,
        dueDate: end,
        dueTime
    }: IInstall): { StartTime: Date; EndTime: Date; IsAllDay: boolean; } {

        if (start === end && start && startTime && end && dueTime) {

            return {
                StartTime: new Date(start + ' ' + startTime),
                EndTime: new Date(end + ' ' + dueTime),
                IsAllDay: false,
            };

        } else if (start && startTime && !end) {

            return {
                StartTime: new Date(start + ' ' + startTime),
                EndTime: new Date(start + 1000 * 60 * 60 * 2),
                IsAllDay: false,
            };

        } else if (end && dueTime && !start) {

            return {
                StartTime: new Date(end + ' ' + dueTime),
                EndTime: new Date(start + 1000 * 60 * 60 * 2),
                IsAllDay: false,
            };

        } else {

            return {
                StartTime: new Date(start || end),
                EndTime: new Date(end || start),
                IsAllDay: true
            };

        }

    },

    filterByLocation(list: IInstall[] = [], locationId?: string): IInstall[] {
        return list.filter(({ aaaLocation: { id } }) => !locationId || id === locationId || id === '18');// Added location id of 'Vended / Buy Out' to show jobs from there in every location
    },

}
